import React, { useEffect } from "react";
import Header from "../../Template/Header";
import axios from "axios";
import * as constant from "../../../Constant";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const OpenCompany=()=>{
    const [accesscompany,setAccesscompany]=React.useState([]);


    const listAccessCompany = async () => {
        try {
            await axios.get(
                `${constant.SERVER_BASE_URL}account/access-company-list`, {
            headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        }).then((resp)=>{
            console.log(resp.data);
            setAccesscompany(resp.data);
        });
        } catch (error) {
            toast.error("Something went wrong!!", {
                position: "top-center",
                className: "text-center",
            });
        }
    };

    useEffect(()=>{
        listAccessCompany();
    },[]);

    return(
        <div className="content">
			<Header />
	<div className="card">
    <div className="card bg-100 shadow-none border"> 
                    <div className="row gx-0 flex-between-center">
                    <div className="col-sm-auto d-flex align-items-center"><img className="ms-n2" src="/assets/img/generic/crm-bar-chart.png" width={90} />
                        <div>
                        <h5 className="text-primary fw-bold mb-0">Open Company</h5>
                        </div>
                        <img className="ms-n4 d-md-none d-lg-block" src="/assets/img/generic/crm-line-chart.png" width={150} />
                    </div>
                    </div>
                </div>
            
            <div className="row p-3">
            
             {
                accesscompany?accesscompany.map((item)=>
                <div className="col-md-4" key={item._id}>
                    <div className="card overflow-hidden" style={{minWidth: '12rem'}}>
                    <div className="bg-holder bg-card" style={{backgroundImage: 'url(/assets/img/generic/corner-2.png)'}} />
                    <div className="card-body position-relative">
                        <div className="d-flex">
                        <h6>{item.firmName}<span className="badge badge-subtle-info rounded-pill ms-2 d-flex" style={{position:'absolute',left:'64px',top:'20px'}}>0.0%</span></h6>
                        <div className="dropdown font-sans-serif btn-reveal-trigger d-flex float-right" style={{position:'absolute',right:0,top:'12px'}}>
                            <button className="btn btn-link text-600 btn-sm dropdown-toggle dropdown-caret-none btn-reveal" type="button" id="dropdown-new-contact" data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true" aria-expanded="false">
                            <svg className="svg-inline--fa fa-ellipsis-h fa-w-16 fs-11" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis-h" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                            <path fill="currentColor" d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z" /></svg>
                            {/* <span class="fas fa-ellipsis-h fs-11"></span> Font Awesome fontawesome.com */}</button>
                            <div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="dropdown-new-contact" style={{}}>
                                <a className="dropdown-item" href="#!">View</a>
                                <a className="dropdown-item" href="#!">Export</a>
                                
                            </div>
                        </div>

                        </div>
                        <div className="display-4 fs-5 mb-2 fw-normal font-sans-serif text-info" data-countup="{&quot;endValue&quot;:23.434,&quot;decimalPlaces&quot;:2,&quot;suffix&quot;:&quot;k&quot;}">0000</div>
                        
                        <a className="fw-semi-bold fs-10 text-nowrap" href="app/e-commerce/orders/order-list.html">Open Company <i className="fas fa-angle-right mt-1" aria-hidden="true"></i>
                            {/* <svg className="svg-inline--fa fa-angle-right fa-w-8 ms-1" data-fa-transform="down-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg style={{transformOrigin: '0.25em 0.5625em'}}>
                            <g transform="translate(128 256)">
                            <g transform="translate(0, 32)  scale(1, 1)  rotate(0 0 0)">
                                <path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" transform="translate(-128 -256)" /></g>
                            </g>
                            </svg> */}
                        </a>
                    </div>
                    </div>
                </div>
                ):null
                }
            </div>
            



	</div>
</div>
    );
}

export default OpenCompany;