import React,{useEffect} from "react";
import Header from "../../Template/Header";
import axios from "axios";
import * as constant from "../../../Constant";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from 'react-bootstrap/Spinner';
import CallRecordsSket from "../../Skeleton/Call/CallrecordsSket";




const initialSearchValue={
    staffid:null,
    date:''
};

const CallRecords=()=>{
    const [params,setParams]=React.useState(null);
    const [downloadparams,setDownloadparams]=React.useState(null);
    const [metadata,setMetadata]=React.useState([]);
    const [searchcount,setSearchcount]=React.useState(0);
    const [skip,setSkip]=React.useState(0);
    const [callStaff,setCallerStaff]=React.useState([]);
    const [loader,setLoader]=React.useState(false);
    const [downloader,setDownloader]=React.useState(false);
    const [showclear,setShowclear]=React.useState(false);
    const [showskeleton,setShowskeleton]=React.useState(false);
    const [graphdata,setGraphdata]=React.useState([]);
    var [ind,setInd]=React.useState(0);





    const sheetmetaRecords=async (offset=0,limit=10,params)=>{
        await axios.get(`${constant.SERVER_BASE_URL}datasheet/get-sheet-metadata-all-records`,{
            params:{
                offset:offset,
                limit:limit,
                qp:params
            },
            headers:{
                "Content-Type":"application/json",
                authorization:`Bearer ${localStorage.getItem('token')}`
            }
        }).then((resp)=>{
            setShowskeleton(true);
            if(resp.data.status==='success'){
              
              setGraphdata(resp.data.datacount);
              setShowskeleton(false);
              setSearchcount(resp.data.totalcount);
              setMetadata(resp.data.value);
             
              setLoader(false);
            }else{
              setShowskeleton(false);
            }
           
        })
    }


    const listCallerStaffs = async () => {
      
        try {
      


              await axios.get(
                `${constant.SERVER_BASE_URL}staff/get-employees`, {
                    headers: {
                        "Content-Type":"application/json",
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }).then((resp)=>{
                  var staffArray=resp.data;
                  let filteredUsers = staffArray.filter((user) => {
                    return user.Designation==="CALLER";
                });
                    setCallerStaff(filteredUsers);
                });


            
        
           
            
        } catch (error) {
            toast.error("Something went wrong!!", {
                position: "top-center",
                className: "text-center",
            });
        }
    };


    const optionStaff=callStaff.map((item)=>({
        value:item._id,
        label:item.EmployeeName
    
    }));



    const searchRecords=useFormik({
        initialValues:initialSearchValue,
        validationSchema:'',
        onSubmit:(values,action)=>{
         
              setParams(values);
              setLoader(true);
              setShowclear(true);
              sheetmetaRecords(0,1000,values);
        }
      });

      const ChangeCaller=(e)=>{
        let event={
          target: {
            name: "staffid",
            value: e,
          },
        };

        let downloadparamsobj={
          date:downloadparams?downloadparams.date:null,
          staffid: event.target.value
        };

        setDownloadparams(downloadparamsobj);
        searchRecords.handleChange(event);
      };

      const ChangeDate=(e)=>{
        let event={
          date:e.target.value,
          staffid:downloadparams?downloadparams.staffid:null
        };

        setDownloadparams(event);
        searchRecords.handleChange(e);
      };

      // onChange={searchRecords.handleChange}

    const renderViewStatus=(status,notes)=>{
        switch (status) {
          case 'Others':
              return (
                <>
                <span className="badge rounded-pill ms-2 badge-subtle-success" style={{fontSize: 12}}>Others</span>
                  <p className="fs-11 text-500 bg-light mt-1 p-1 rounded">{notes}</p>
                </>
              );
          case 'Not Answered':
            return (
              <span className="badge rounded-pill ms-2 badge-subtle-warning" style={{fontSize: 12}}>Not Answered</span>
            );
          case 'Call Another Time':
            return (
              <span className="badge rounded-pill ms-2 badge-subtle-info" style={{fontSize: 12}}>Call Another Time</span>
            );
          case 'Number Invalid':
            return (
              <span className="badge rounded-pill ms-2 badge-subtle-danger" style={{fontSize: 12}}>Call Another Time</span>
            );
          case 'Not Interested':
            return (
              <span className="badge rounded-pill ms-2 bg-400" style={{fontSize: 12}}>Call Another Time</span>
            );
            case 'Demo':
              return (
                <span className="badge rounded-pill ms-2 bg-dark text-white" style={{fontSize: 12}}>Demo</span>
              );
          default:
            return <></>;
        }
      }

      const AgentAvatar=(remarks,name)=>{
        switch (remarks) {
            case 'Others':
                return (
                  <>
                    <div className="avatar-name rounded-circle bg-success-subtle text-dark">
                        <span className="fs-9 text-success">{name.charAt(0)}</span>
                    </div>
                  </>
                );
            case 'Not Answered':
              return (
                <div className="avatar-name rounded-circle bg-warning-subtle text-dark">
                        <span className="fs-9 text-warning">{name.charAt(0)}</span>
                    </div>
              );
            case 'Call Another Time':
              return (
                <div className="avatar-name rounded-circle bg-info-subtle text-dark">
                        <span className="fs-9 text-info">{name.charAt(0)}</span>
                    </div>
              );
            case 'Number Invalid':
              return (
                <div className="avatar-name rounded-circle bg-danger-subtle text-dark">
                        <span className="fs-9 text-danger">{name.charAt(0)}</span>
                    </div>
              );
            case 'Not Interested':
              return (
                <div className="avatar-name rounded-circle bg-400 text-dark">
                <span className="fs-9 text-dark">{name.charAt(0)}</span>
            </div>
              );
              case 'Demo':
                return (
                  <div className="avatar-name rounded-circle bg-dark">
                  <span className="fs-9 text-white">{name.charAt(0)}</span>
              </div>
                );
            default:
              return <></>;
          }
      }


      
const handlePageClick=(data)=>{
    let limit=10;
    let offset=data.selected*limit;
    setSkip(offset);
    sheetmetaRecords(offset,limit,params);
  }
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '200px',
      height:'10px!important',
      marginRight:'0px'
     
    })
};

const clearFilter=()=>{
  setShowclear(false);
  sheetmetaRecords(0,10,null);
 
}

  const searchdate=searchRecords.values.date;
  var selectedstaff=null;
   if(searchRecords.values.staffid){
      if('value' in searchRecords.values.staffid){
        selectedstaff=searchRecords.values.staffid.value;
      }
    
  }
 if(searchdate!=='' || selectedstaff!==null){
  var element=document.getElementById('btnsearch');
  if(element){
    element.classList.remove('disabled');
  }
 }

    const downloadCallRecords=async (event)=>{
        console.log("downloadparams",downloadparams);
        setDownloader(true);
          await axios.get(`${constant.SERVER_BASE_URL}datasheet/download-sheet-metadata`,{
            responseType: 'blob',
            params:downloadparams,
            headers:{
                authorization:`Bearer ${localStorage.getItem('token')}`
            }
        }).then((resp)=>{
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'report.xlsx'); // Specify the file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setDownloader(false);
        });

    }

    useEffect(()=>{
        sheetmetaRecords();
        listCallerStaffs();
    },[]);
    return (
        <div className="content">
        <Header />
       
        <div className="card">
            <div className="card-header bg-200 border-top border-info shadow d-flex">
                <div className="d-flex me-2 mt-3">
                  <h6>Call Records</h6>
                </div>


                <form onSubmit={searchRecords.handleSubmit } className="ms-2">
                  
              
                  

                <div className="row justify-content-end">

                
                  
                   
                    <div className="col-auto m-0">
                    <div className="form-group d-inline-flex m-1">
                            <Select 
                            styles={customStyles}
                            name="staffid"
                            id="staffid"
                            placeholder="Select"
                            onBlur={searchRecords.handleBlur}
                            onChange={ChangeCaller.bind(this)}
                            options={optionStaff}
                        />

                            </div>
                            <div className="form-group d-inline-flex m-1">
                                    <input type="date"  onChange={ChangeDate.bind(this)} onBlur={searchRecords.handleBlur} name="date"  value={searchRecords.values.date} className="form-control form-control-md" style={{height:'37px',background:'white'}}  />
                            </div>
                            <div className="form-group d-inline-flex m-1">
                                    {
                                      loader===false?
                                      <button className="btn btn-info disabled" id="btnsearch" type="submit">
                                        <i className="fa fa-search" aria-hidden="true"></i> Search
                                    </button>
                                    :null
                                    }
                                    {
                                      loader===true?
                                      <button className="btn btn-info disabled" type="submit">

                                        <div style={{display:'inline-flex'}} className="mb-2">
                                          <p className="mb-0">Loading...</p>
                                          <Spinner animation="border" variant="info" size="sm" />
                                        </div>

                                    </button>
                                    :null
                                    }
                                    {
                                      showclear?
                                      <button type="button" id="clearbutton" className="btn btn-danger ms-2" onClick={clearFilter}> <i className="fas fa-times-circle"></i> Clear Filter</button>
                                      :null
                                    }
                                    
                                    
                            </div>

                            <div className="form-group d-inline-flex ms-3">

                            {
                                      downloader===false?
                                      <button className="btn btn-outline-info btn-sm" style={{height:'36px'}} onClick={downloadCallRecords} type="button">
                                        <i className="fas fa-cloud-download-alt" aria-hidden="true"></i> Download
                                        </button>
                                    :null
                                    }
                                    {
                                      downloader===true?
                                    
                                    <button className="btn btn-outline-info btn-sm" style={{height:'36px'}} type="button">
                                    <div style={{display:'inline-flex'}} className="mb-2">
                                          <p className="mb-0">Loading...</p>
                                          <Spinner animation="border" variant="info" size="sm" />
                                        </div>
                                    </button>

                                    :null
                                    }

                                    
                            </div>
                    </div>

                  

                </div>
                </form>
            </div>


          <div className="card h-lg-100 overflow-hidden">
          

                <div className="card-body p-0">

                
                 <div className="row g-0 align-items-center py-2 position-relative border-bottom border-200 ms-2">
                  {graphdata?graphdata.map((item)=>
                    <div className="col-auto border-end-lg border-bottom border-bottom-lg-0 pb-3 pb-lg-0" key={`xsjdbd${ind++}`}>
                      <div className="d-flex flex-between-center mb-3 ms-2">
                        <div className="d-flex align-items-center">
                          <div className={`icon-item icon-item-sm ${item.colorclass}-subtle shadow-none me-1 bg-primary-subtle ms-1`}>
                            <i className={`fas fa-phone ${item.textcolor}`}></i>
                          </div>
                          <h6 className="mb-0 fs-11">{item.label} </h6>
                        </div>
                        
                      </div>
                      <div className="d-flex">
                        <div className="d-flex">
                          <p className="font-sans-serif lh-1 mb-1 fs-16 pe-2 ms-1"> {item.value}</p>
                        </div>
                        <div className="echart-crm-statistics w-100 ms-2" data-echart-responsive="true" data-echarts="{&quot;tooltip&quot;:{&quot;trigger&quot;:&quot;axis&quot;},&quot;xAxis&quot;:{&quot;type&quot;:&quot;category&quot;,&quot;data&quot;:[&quot;June 01&quot;,&quot;June 02&quot;,&quot;June 03&quot;,&quot;June 04&quot;,&quot;June 05&quot;,&quot;June 06&quot;,&quot;June 07&quot;]},&quot;series&quot;:[{&quot;type&quot;:&quot;line&quot;,&quot;data&quot;:[220,230,150,175,200,170,70,160],&quot;color&quot;:&quot;#2c7be5&quot;,&quot;areaStyle&quot;:{&quot;color&quot;:{&quot;colorStops&quot;:[{&quot;offset&quot;:0,&quot;color&quot;:&quot;#2c7be53A&quot;},{&quot;offset&quot;:1,&quot;color&quot;:&quot;#2c7be50A&quot;}]}}}],&quot;grid&quot;:{&quot;bottom&quot;:&quot;-10px&quot;}}" style={{userSelect: 'none', position: 'relative'}} _echarts_instance_="ec_1727161227000"><div style={{position: 'relative', width: 111, height: 32, padding: 0, margin: 0, borderWidth: 0}}><canvas style={{position: 'absolute', left: 0, top: 0, width: 111, height: 32, userSelect: 'none', padding: 0, margin: 0, borderWidth: 0}} data-zr-dom-id="zr_0" width={166} height={48} /></div><div className /></div>
                      </div>
                    </div>
                  ):null
                  }

                  </div>




                


                  {showskeleton?<CallRecordsSket />:null}
                    { metadata? metadata.map((item)=>
                    <div className="row g-0 align-items-center py-2 position-relative border-bottom border-200" key={item._id}>
                    <div className="col ps-x1 py-1 position-static">
                        <div className="d-flex align-items-center">
                        <div className="avatar avatar-xl me-3">
                            {AgentAvatar(item.remarks,item.updatedby.EmployeeName)}
                          
                        </div>
                        <div className="flex-1">
                            <h6 className="mb-0 d-flex align-items-center">{item.updatedby.EmployeeName}</h6>
                        </div>
                        </div>
                    </div>
                    <div className="col ps-x1 py-1 position-static">
                        <div className="d-flex align-items-center">
                        <div className="flex-1">
                            <h6 className="mb-0 d-flex align-items-center">{item.mobileno}</h6>
                            {item.isCustomer?
                                <span className="badge rounded-pill badge-subtle-success" style={{fontSize:'9px'}}><i className="fas fa-check"></i> Customer</span>
                            :null
                            }
                        </div>
                        </div>
                    </div>
                    <div className="col ps-x1 py-1 position-static">
                        <div className="d-flex align-items-center">
                        <div className="flex-1">
                           { renderViewStatus(item.remarks,item.notes)}
                        </div>
                        </div>
                    </div>
                    <div className="col py-1">
                        <div className="row flex-end-center g-0">
                        <div className="col-auto pe-2">
                            <div className="fs-10 fw-semi-bold">{moment(item.updatedAt).format('DD, MMM YYYY, h:mm')}</div>
                        </div>
                       
                        </div>
                    </div>
                    </div>
                    ):<p>No records found</p>
                    }

                </div>

                { searchcount>10?
                <div className="bg-200 p-3 border-info border-bottom">
                <ReactPaginate
                    previousLabel={`Previous`}
                    nextLabel={`Next`}
                    breakLabel={`...`}

                    pageCount={searchcount/10}
                    onPageChange={handlePageClick}
                    containerClassName="pagination pagination-sm m-0 float-right"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    activeClassName="active"
                    activeLinkClassName="active"
                    />
                    </div>
                    :null
                }
                    </div>
                


            {/* Place content here */}
                    
        </div>
    </div>
    );
}
export default CallRecords;