import React,{useEffect} from "react";
import Header from "../../Template/Header";
import axios from "axios";
import * as constant from "../../../Constant";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { companyAccessValidation } from "../../Validations/FormValidation";
import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';




const initialValues = {
    staffid: "",
    firms: ""
  };

const GrantCompanyPermission=()=>{

    const [allStaffData, setAllStaffData] = React.useState([]);
    const [allFirmsData, setAllFirmsData] = React.useState([]);
    const [loader,setLoader]=React.useState(false);

    const redirect = useNavigate();

    
  const listStaffs = async () => {
    try {
        await axios.get(
            `${constant.SERVER_BASE_URL}staff/get-employees`, {
                headers: {
                    "Content-Type":"application/json",
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }).then((resp)=>{
                setAllStaffData(resp.data);
            });
        
    } catch (error) {
        // console.log(error);
        toast.error("Something went wrong!!", {
            position: "top-center",
            className: "text-center",
        });
    }
};

const listFirms = async () => {
    try {
        const response = await axios.get(
            `${constant.SERVER_BASE_URL}firm/get-firms`, {
        headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
        }
    });
        setAllFirmsData(response.data);
    } catch (error) {
        toast.error("Something went wrong!!", {
            position: "top-center",
            className: "text-center",
        });
    }
};



const grantPermission=useFormik({
    initialValues:initialValues,
    validationSchema:companyAccessValidation,
    onSubmit:(values,action)=>{
      Swal.fire({
        title: "Are you sure?",
        text: "You want to  grant the company access!!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Grant it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
           
          setLoader(true);
            
         
            await axios.post(`${constant.SERVER_BASE_URL}account/grant-company-access`,
              values,
            {
              headers:{
                "Content-Type":"application/json",
                authorization:`Bearer ${localStorage.getItem('token')}`
              }
            }).then((resp)=>{
              if(resp.data.status==="SUCCESS"){
                setLoader(false);
                 toast.success(resp.data.message, {
                  position: "top-center",
                  className: "text-center",
                });
                action.resetForm();
              }else if(resp.data.status==="ERROR"){
                setLoader(false);
                toast.error(resp.data.message, {
                  position: "top-center",
                  className: "text-center",
                });
              }else if(resp.data.status==="TOKEN_EXPIRED"){
                setLoader(false);
                localStorage.removeItem("adminLogin");
                localStorage.removeItem("token");
                redirect("/login");
                window.location.reload();
              }
            }).then((err)=>{
              setLoader(false);
              toast.error(err, {
                position: "top-center",
                className: "text-center",
              });
            });

        }
      });
    }
  });



    const staffOptions=allStaffData.map(item=>({
        value:item._id,
        label:item.EmployeeName+`(${item.EmployeeEmail})`
    }));
    
    const firmsOptions=allFirmsData.map(item=>({
        value:item._id,
        label:item.firmName
    }));

    useEffect(()=>{
        listStaffs();
        listFirms();
    },[]);

    return (
        <div className="content">
         <Header />
         <div className="row g-3 mb-3">
            <div className="col-md-12">
                <div className="card bg-100 shadow-none border"> 
                    <div className="row gx-0 flex-between-center">
                    <div className="col-sm-auto d-flex align-items-center"><img className="ms-n2" src="/assets/img/generic/crm-bar-chart.png" width={90} />
                        <div>
                        <h6 className="text-primary fs-10 mb-0">Welcome to </h6>
                        <h5 className="text-primary fw-bold mb-0">Company Permission</h5>
                        </div>
                        <img className="ms-n4 d-md-none d-lg-block" src="/assets/img/generic/crm-line-chart.png" width={150} />
                    </div>
                    </div>
                </div>
            </div>
            </div>


            <div className="row">
                <div className="col-lg-6 pe-lg-2 mb-3">
                    <div className="card overflow-hidden">
                        <div className="card-header bg-200 border-top border-info">
                        <div className="row align-items-center">
                            <div className="col">
                                <h6 className="mb-0">Grant Company Permission</h6>
                            </div>
                            </div>
                        </div>
                    <div className="card-body d-flex flex-column justify-content-end">
                        <form onSubmit={grantPermission.handleSubmit}>
                            <div className="row justify-content-center">
                                    <div className="col-md-10">
                                        <label>Select Staff</label>
                                        <div className="form-group">
                                        <Select name="staffid" id="staffid" placeholder="Select" onBlur={grantPermission.handleBlur} onChange={(selectedOption) => {
                                                    let event = {
                                                        target: {
                                                            name: "staffid",
                                                            value: selectedOption,
                                                        },
                                                    };
                                                    grantPermission.handleChange(event);
                                                }}
                                                options={staffOptions}
                                            />
                                        {grantPermission.errors.staffid && grantPermission.touched.staffid ? (
                                            <div className="alert alert-danger small alert-dismissible p-1 mt-2" role="alert">
                                            {grantPermission.errors.staffid.label}
                                            </div>
                                        ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-10 mt-3 mb-3">
                                        <label>Select Company</label>
                                        <div className="form-group">
                                        <Select
                                            isMulti
                                            name="firms"
                                            id="firms"
                                            placeholder="Select"
                                            onBlur={grantPermission.handleBlur}
                                            onChange={(selectedOption) => {
                                                let event = {
                                                    target: {
                                                        name: "firms",
                                                        value: selectedOption,
                                                    },
                                                };
                                                grantPermission.handleChange(event);
                                            }}
                                            options={firmsOptions}
                                            />
                                             {grantPermission.errors.firms && grantPermission.touched.firms ? (
                                            <div className="alert alert-danger small alert-dismissible p-1 mt-2" role="alert">
                                                {grantPermission.errors.firms}
                                            </div>
                                        ) : null}
                                        </div>
                                    </div>

                                    <div className="col-md-9 mb-5">

                                        {
                                        loader===false?
                                        <button className="btn btn-sm btn-outline-info mt-3">
                                            Submit
                                        </button>
                                        :null
                                        }

                                    {
                                    loader===true?
                                        <button className="btn btn-sm btn-outline-info mt-3 disabled">
                                        Loading...
                                        <Spinner animation="border" variant="info" size="sm" />
                                        </button>
                                        :null
                                    }

                                           
                                    </div>

                            </div>
                            </form>
                    </div>
                    </div>
                </div>

                <div className="col-lg-6 pe-lg-2 mb-3">
                    <div className="card h-lg-100 overflow-hidden">
                    <div className="card-header bg-200 border-top border-info">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6 className="mb-0"></h6>
                        </div>
                        
                        </div>
                    </div>
                    <div className="card-body d-flex flex-column justify-content-end">

                    </div>
                    </div>
                </div>

            </div>


            </div>
    );
}

export default GrantCompanyPermission;