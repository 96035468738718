import React, { useEffect } from "react";
import Header from "../Template/Header";
import * as constant from "../../Constant";
import { Link,useParams,useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Modal } from 'react-bootstrap';
import {useFormik} from "formik";
import { addOtherNotes } from "../Validations/FormValidation";
import ReactPaginate from "react-paginate";



const initialValues={
	notes:'',
	remarks:''
};

const CallingList = () => {
	const redirect = useNavigate();
	const [sheetmetadata,setSheetmetadata]=React.useState([]);
	let [serialNo, setSerialNo] = React.useState(1);
	let [sheetstatus, setSheetstatus] = React.useState("");
	const [rowdata,setRowdata]=React.useState({});
	const [searchKey,setSearchKey]=React.useState('');
	
	const [show, setShow] = React.useState(false);
	const [showModal, setShowModal] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleCloseModal = () => setShowModal(false);
    const [searchcount,setSearchcount]=React.useState(0);
	const [skip,setSkip]=React.useState(0);
	const [params,setParams]=React.useState(null);
	const [formData, setFormData] = React.useState({
		mobileNumber:"",
		message: "",
	});
  const [whatsapp_err, setWhatsapp_err] = React.useState('');
  


	const {sheetid}=useParams();

	
    
    const getSheetRecords=async (offset=0,limit=10,params)=>{
		await axios.get(`${constant.SERVER_BASE_URL}datasheet/get-sheet-records/${sheetid}`,{
			params:{
				offset:offset,
				limit:limit,
				qp:params
			},
			headers:{
				"Content-Type":"application/json",
				authorization:`Bearer ${localStorage.getItem('token')}`
			}
		}).then((resp)=>{
			if(resp.data.status==="SUCCESS"){
				console.log(resp.data);
				setSheetstatus(resp.data.value[0].sheetid.status);
				setSheetmetadata(resp.data.value);
				setSearchcount(resp.data.totalcount);
			}else{
				toast.error(`${resp.data.message}`, {
					position: "top-center"
				  });
			}
		});
	}

	useEffect(()=>{
		getSheetRecords(0,10,null);
	},[]); 

	const setRemarks=async (sr)=>{
		
		let selectedOption=sr.target.value;
		let itemid=sr.target.dataset.id;
		var selectedText="";
		if(selectedOption==="Not Answered"){
			selectedText="<span class='badge rounded-pill ms-2 badge-subtle-warning'>"+selectedOption+"</span>";
		}else if(selectedOption==="Number Invalid"){
			selectedText="<span class='badge rounded-pill ms-2 badge-subtle-danger'>"+selectedOption+"</span>";
		}else if(selectedOption==="Not Interested"){
			selectedText="<span class='badge rounded-pill ms-2 bg-400'>"+selectedOption+"</span>";
		}else if(selectedOption==="Call Another Time"){
			selectedText="<span class='badge rounded-pill ms-2 badge-subtle-info'>"+selectedOption+"</span>";
		}else if(selectedOption==="Demo"){
			selectedText="<span class='badge rounded-pill ms-2 badge-subtle-dark text-white'>"+selectedOption+"</span>";
		}else if(selectedOption==="Others"){
			setShow(true);
			let rowdata={
				remark:selectedOption,
				mobileno:sr.target.dataset.mobile,
				itemid:itemid
			};
			setRowdata(rowdata);
			return;
		}else{
			selectedText="";
		}
		document.getElementById('remark_'+itemid).innerHTML=selectedText;
		var dataObj={
			itemid:itemid,
			remark:selectedOption
		};
		await axios.patch(`${constant.SERVER_BASE_URL}datasheet/update-sheet-metadata`,dataObj,{
			headers:{
				"Content-Type":"application/json",
				authorization:`Bearer ${localStorage.getItem('token')}`
			}
		}).then((resp)=>{
			if(resp.data.status==="SUCCESS"){
				toast.success(`${resp.data.message}`, {
					position: "top-center"
				  });
				 
			}else{
				toast.error(`${resp.data.message}`, {
					position: "top-center"
				  });
			}
		});
	}

	const makeCall=async (mobileno)=>{
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to make a new call!!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, Place it'
		  }).then(async (result) => {
			if (result.isConfirmed) {
				var dataObj={
						to_number:mobileno,
					};
				await axios.get(`${constant.SERVER_BASE_URL}datasheet/make-a-call`,
				{
					params:dataObj,
					headers:{
					    "Content-Type":"application/json",
						authorization: `Bearer ${localStorage.getItem('token')}`
					}
				}).then((resp)=>{
					if(resp.data.status==="SUCCESS"){
						toast.success(`${resp.data.message}`, {
							position: "top-center"
						  });
					}else{
						toast.error(`${resp.data.message}`, {
							position: "top-center"
						  });
					}
				});
			}
		});
	}


	const remarks=(remark)=>{
		if(remark==="Not Answered"){
			return <span className="badge rounded-pill ms-2 badge-subtle-warning">{remark}</span>
		}else if(remark==="Number Invalid"){
			return <span className='badge rounded-pill ms-2 badge-subtle-danger'>{remark}</span>
		}else if(remark==="Not Interested"){
			return <span className='badge rounded-pill ms-2 bg-400'>{remark}</span>
		}else if(remark==="Call Another Time"){
			return <span className='badge rounded-pill ms-2 badge-subtle-info'>{remark}</span>
		}else if(remark==="Others"){
			return <span className='badge rounded-pill ms-2 badge-subtle-success'>{remark}</span>;
		}else if(remark==="Demo"){
			return <span className='badge rounded-pill ms-2 badge-subtle-dark text-white'>{remark}</span>;
		}else{
			return "";
		}
	}


	
	const saveOtherNotes=useFormik({
		initialValues:initialValues,
		validationSchema:addOtherNotes,
		onSubmit:(values,action)=>{
		  Swal.fire({
			title: 'Are you sure?',
			text: "You want to save this notes!!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, Save it!'
		  }).then(async (result) => {
			if (result.isConfirmed) {
				var id=document.getElementById('hiddenid').value;
				values.remarks=document.getElementById('remarkshidden').value;
				if(values.remarks!==null && values.notes!==null){
			  await axios.patch(
				`${constant.SERVER_BASE_URL}datasheet/save-call-notes/${id}`,
				values, {
				  headers: {
					"Content-Type":"application/json",
					authorization: `Bearer ${localStorage.getItem("token")}`,
				  }
				}
			  ).then((resp)=>{
				if(resp.data.status==="SUCCESS"){
					toast.success(`${resp.data.message}`,{
						position: "top-center"
					  });
					  getSheetRecords();
					  setShow(false);
					 var selectedText="<span class='badge rounded-pill ms-2 badge-subtle-success'>Others</span> <p>"+values.notes+"</p>";
					  document.getElementById('remark_'+id).innerHTML=selectedText;
				}else if(resp.data.status==="ERROR"){
					toast.error(`${resp.data.message}`,{
						position: "top-center"
					  });
				}else if(resp.data.status==="TOKEN_EXPIRED"){
						localStorage.removeItem("adminLogin");
						localStorage.removeItem("token");
						redirect("/login");
						window.location.reload();
				}
			  }).catch((error)=>{
				toast.error("Something went wrong!!", {
				  position: "top-center",
				  className: "text-center"
				});
			  }) 
			}
			}
		  });
	
		}
	  });

	  const markAsCustomer=async (item)=>{
		Swal.fire({
			title: "Enter Customer Name",
			icon:'info',
			input: "text",
			inputAttributes: {
			  autocapitalize: "off",
			  placeholder:"Customer Name..",
			  required:true
			},
			showCancelButton: true,
			cancelButtonColor:'#d33',
			confirmButtonText: "Save",
			confirmButtonColor:"#3fc3ee",
			showLoaderOnConfirm: true,
		  }).then(async (result) => {
			if (result.isConfirmed) {
					var dataObj={
						name:result.value,
						itemid:item._id,
						mobile:item.mobileno
					};
					
					await axios.post(`${constant.SERVER_BASE_URL}datasheet/save-customer`,
						dataObj,
						{
						headers:{
						    "Content-Type":"application/json",
							authorization: `Bearer ${localStorage.getItem('token')}`
						}
					}).then((resp)=>{
						if(resp.data.status==="SUCCESS"){
							toast.success(`${resp.data.message}`,{
								position: "top-center"
							  });
							  getSheetRecords();
						}else if(resp.data.status==="ERROR"){
							toast.error(`${resp.data.message}`,{
								position: "top-center"
							  });
						}
					});
			}
		  });
	  };

	  const submitSheet=async (sheetid)=>{
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to submit this sheet!!",
			icon: 'info',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, Submit it!'
		  }).then(async (result) => {
			if (result.isConfirmed) {
				await axios.get(`${constant.SERVER_BASE_URL}datasheet/close-sheet/${sheetid}`,{
					headers:{
						"Content-Type":"application/json",
						authorization:`Bearer ${localStorage.getItem('token')}`
					}
				}).then((resp)=>{
					if(resp.data.status==="SUCCESS"){
						toast.success(`${resp.data.message}`,{
							position: "top-center"
						  });
					}else{
						toast.error(`${resp.data.message}`,{
							position: "top-center"
						  });
					}
				});
			}
		});
	  }

	  /* const requestToShow=(obj)=>{
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to send a request to show this number!!",
			icon: 'info',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, Send it!'
		  }).then(async (result) => {
			if (result.isConfirmed) {

				Swal.fire({
					title: "Please mention a valid reason below.",
					input: "text",
					icon: 'info',
					inputAttributes: {
					  autocapitalize: "off"
					},
					showCancelButton: true,
					confirmButtonText: "Send Request",
					showLoaderOnConfirm: true,
					preConfirm: async (login) => {
					  try {
						
					  } catch (error) {
						Swal.showValidationMessage(`
						  Request failed: ${error}
						`);
					  }
					},
					allowOutsideClick: () => !Swal.isLoading()
				  }).then((result) => {
					if (result.isConfirmed) {
						obj.notes=result.value;
						axios.get(`${constant.SERVER_BASE_URL}datasheet/send-request-mobileno-visibility`,{
							params:obj,
							headers:{
								"Content-Type":"application/json",
								authorization: `Bearer ${localStorage.getItem('token')}`
							}
						}).then((resp)=>{
							console.log(resp.data.status);
							if(resp.data.status==="SUCCESS"){
								toast.success(`${resp.data.message}`,{
									position: "top-center"
								  });
								  getSheetRecords();
							}else if(resp.data.status==="ERROR"){
								toast.error(`${resp.data.message}`,{
									position: "top-center"
								  });
							}else if(resp.data.status==="TOKEN_EXPIRED"){
								localStorage.removeItem("adminLogin");
								localStorage.removeItem("token");
								redirect("/login");
								window.location.reload();
							}else{
								toast.error(`Something went wrong, Please try again later`,{
									position: "top-center"
								  });
							}
						});
					}
				  });



			}
		});
	  } */

	  const RefreshPage=()=>{
		getSheetRecords(0,10,null);
	  }

	  const sendWhatsApp=(mobileno)=>{
		setShowModal(true);
		setFormData({mobileNumber:mobileno});
	  }
	 
	  const { mobileNumber, message } = formData;

		const onChangeWAFields = (e) => {
			e.preventDefault();
			setFormData({
			...formData,
			[e.target.name]: e.target.value,
			});
		};

		const handleError=()=>{
			const phonePattern = /^[0-9]{10}$/;
			var MobileErrFlag=false;
			var MessageErrFlag=false;
				setWhatsapp_err('');
				if(!formData.mobileNumber){
					setWhatsapp_err("Mobile No. is required");			
				}else if(formData.mobileNumber){
					if(((formData.mobileNumber).length) < 10  || ((formData.mobileNumber).length) > 10){
						setWhatsapp_err("Mobile No. can not be less/more than 10 digits");
					}else if(!phonePattern.test(formData.mobileNumber)){
						setWhatsapp_err("Please enter a valid mobile no.");
					}else{
						MobileErrFlag= true;
					}
				}
				if(!formData.message){
					setWhatsapp_err("Message field is required");
				}else{
					MessageErrFlag=true;
				}
				if(MobileErrFlag===true && MessageErrFlag===true){
					return true;
				}else{
					return false;
				}
		}


	const handleSendMessage = (e) => {
		e.preventDefault();
		const phoneNumbers = formData.mobileNumber; // Add the recipient's phone number
		const messages = formData.message;
		if(handleError()===true){
			const url = `https://wa.me/${phoneNumbers}?text=${messages}`;
			window.open(url, '_blank');
		}
	};


		const searchRecordsByNumber=(event)=>{

				const mobile=event.target.value;
				console.log(mobile);

				setSearchKey(mobile);

				var qp={
					mobileno:mobile
				};
				if(mobile!==''){
					getSheetRecords(0,1000,qp);
				}else{
					getSheetRecords(0,10,null);
				}
				
		}



		const filteredMetadataByRemarks=(e)=>{
				const remark=e.target.value;
				var qp={
					remarks:remark
				};
				
				if(remark!==''){
					setParams(qp);
					getSheetRecords(0,1000,qp);
				}else{
					getSheetRecords(0,3,null);
				}
		}



       
const handlePageClick=(data)=>{
    let limit=10;
    let offset=data.selected*limit;
    setSkip(offset);
    getSheetRecords(offset,limit,null);
  }



	return (
		<div className="content">
			<Header />
			<div className="card">

  

				<div className="card-header border-bottom">
					<div className="row flex-between-end">
						<div className="col-auto align-self-center">
							<h5
								className="mb-0"
								data-anchor="data-anchor"
								id="responsive-table"
							>
								List Sheets
								<a
									className="anchorjs-link "
									aria-label="Anchor"
									data-anchorjs-icon="#"
									href="#responsive-table"
									style={{
										marginLeft: "0.1875em",
										paddingRight: "0.1875em",
										paddingLeft: "0.1875em",
									}}
								/>
							</h5>
						</div>
						<div className="col-auto ms-auto">
							<div
								className="nav nav-pills nav-pills-falcon flex-grow-1 mt-2"
								role="tablist"
							>
								<Link
									className="btn btn-sm btn-outline-info"
									to="/calling-records"
								>
								
									<i className="fas fa-chart-pie"></i> Calling Dash
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="card-body pt-0">

					<div className="row gx-0 flex-between-center bg-100 shadow-none border-bottom">

						<div className="col-auto">
							<button className="btn btn-outline-info btn-sm float-right" onClick={RefreshPage}> 
								<i style={{fontSize:"16px"}} className="fa">&#xf021;</i> 
							</button>
						</div>

						<div className="col-auto">
							<input type="text" onChange={searchRecordsByNumber} value={searchKey}  className="form-control form-control-sm" pattern="[0-9]{10}" placeholder="Search Mobile No."   maxLength={10} />
						</div>

						<div className="col-auto">
							<select className="form-control form-control-sm" onChange={filteredMetadataByRemarks}>
									<option value="" key="">----Select----</option>
									<option value="Not Answered" key="Not Answered">Not Answered</option>
									<option value="Call Another Time" key="Call Another Time">Call Another Time</option>
									<option value="Number Invalid" key="Number Invalid">Number Invalid</option>
									<option value="Not Interested" key="Not Interested">Not Interested</option>
									<option value="Demo" key="Demo">Demo</option>
									<option value="Others" key="Others">Others</option>
							</select>
						</div>

						<div className="col-auto p-3 ">
							{
								sheetstatus==="SUBMITTED"?
								<button onClick={submitSheet.bind(this,sheetid)} type="button" className="btn btn-sm btn-outline-success float-end"> <i className="fas fa-paper-plane"></i> Closed</button>
								:
								<button onClick={submitSheet.bind(this,sheetid)} type="button" className="btn btn-sm btn-outline-info float-end"> <i className="fas fa-paper-plane"></i> Submit Sheet</button>
							}	
						</div>
					</div>
					<div className="tab-content">
						<div
							className="tab-pane preview-tab-pane active"
							role="tabpanel"
							aria-labelledby="tab-dom-1c5a7061-098a-4658-a94b-061184a7cbbb"
							id="dom-1c5a7061-098a-4658-a94b-061184a7cbbb"
						>
							<div className="table-responsive scrollbar">
								<table className="table table-hover table-striped overflow-hidden">
									<thead>
										<tr>
											<th>S.No.</th>
											<th>Mobile No</th>
											<th style={{width:"10px"}}>Remarks</th>
											<th></th>
											<th></th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
									{sheetmetadata.length>0?sheetmetadata.map((item)=>
									<tr	className="align-middle" key={item._id}>
										<td className="text-nowrap">
											<div className="d-flex align-items-center">
												<div className="ms-2">{serialNo++}</div>
											</div>
										</td>
										<td className="text-nowrap fs-11"> 
												
													<div>
														<span className="badge rounded-pill ms-2 px-2 badge-subtle-success" style={{fontSize:"11px"}}>{item.mobileno}</span>
												  </div>
												
												

											</td>
										<td>
										
											<select className="form-select w-25" data-id={item._id} data-mobile={item.mobileno} onChange={setRemarks.bind(this)}>
												<option value></option>
												<option value="Not Answered">Not Answered</option>
												<option value="Number Invalid">Number Invalid</option>
												<option value="Not Interested">Not Interested</option>
												<option value="Call Another Time">Call Another Time</option>
												<option value="Demo">Demo</option>
												<option value="Others">Others</option>
											</select>
											
										</td>
										<td>
										<div className="d-flex align-items-center">
												<div className="ms-2" id={"remark_"+item._id}>
														{item.remarks?remarks(item.remarks):null}
														
													</div>
													
											</div>
											<p className="fs-11">{item.notes?item.notes:null}</p>
										</td>
										<td>
										{item.isCustomer?<span className='badge rounded-pill ms-2 badge-subtle-success'> <i className="fas fa-check"></i> Customer</span>:
										<button onClick={markAsCustomer.bind(this,item)} disabled={item.remarks==="Others"?false:true} className="btn btn-sm btn-outline-info fs-11">Add To Customer</button> }
										</td>
										<td className="text-nowrap">
											<button onClick={makeCall.bind(this,item.mobileno)} className="btn btn-sm btn-outline-success m-1 fs-11" type="button"><i className="fa fa-phone"></i></button>
											<button className="btn  m-1 fs-12" onClick={sendWhatsApp.bind(this,`${item.mobileno}`)}>
													<img src="/assets/img/icons/whatsapp.png" alt="" />
												</button>
										</td>
									</tr>
									):
									<tr key="34895798345kj">
											<td colSpan={6}>No records found</td>
									</tr>
								}
													
									</tbody>
								</table>

							</div>

							{ searchcount>10?
								<div className="bg-100 p-3 border-info border-bottom">
								<ReactPaginate
									previousLabel={`Previous`}
									nextLabel={`Next`}
									breakLabel={`...`}

									pageCount={searchcount/10}
									onPageChange={handlePageClick}
									containerClassName="pagination pagination-sm m-0 float-right"
									pageClassName="page-item"
									pageLinkClassName="page-link"
									nextClassName="page-item"
									nextLinkClassName="page-link"
									previousClassName="page-item"
									previousLinkClassName="page-link"
									activeClassName="active"
									activeLinkClassName="active"
									/>
									</div>
									:null
							}



						</div>
					</div>
				</div>
			</div>

			<>
    
	  <Modal show={show} onHide={handleClose}>
        
		<div className="modal-body p-0">
  <div className="rounded-top-3 bg-body-tertiary py-3 ps-4 pe-6">
    <h4 className="mb-1" id="staticBackdropLabel">Add Notes</h4>
	<div className="position-absolute top-0 end-0 mt-3 me-3 z-1">
		<button onClick={handleClose} className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close" />
		</div>

  </div>
  <div className="p-4">
  <form className="form-horizontal" onSubmit={saveOtherNotes.handleSubmit}>
    <div className="row">
      <div className="col-lg-9">
        <div className="d-flex">
		<div className="icon-item icon-item-sm bg-primary-subtle shadow-none me-2 bg-primary-subtle">
			<svg className="svg-inline--fa fa-phone fa-w-16 fs-11 text-primary" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z" /></svg>
			
			</div>

          <div className="flex-1">
            <h5 className="mb-2 fs-9">{rowdata.mobileno?rowdata.mobileno:null}</h5>
            <div className="d-flex"><span className="badge me-1 py-2 badge-subtle-success">{rowdata.remark?rowdata.remark:null}</span>
			
              <div className="dropdown dropend">
			
                <div className="dropdown-menu">
                  <h6 className="dropdown-header py-0 px-3 mb-0">Select Label</h6>
                  <div className="dropdown-divider" />
                  <div className="px-3">
					<button className="badge-subtle-danger dropdown-item rounded-1 mb-2" type="button">New</button>
				 
				  </div>
                  <div className="dropdown-divider" />
                 
                </div>
              </div>
            </div>
            <hr className="my-4" />
          </div>
        </div>
        <div className="d-flex">
			
          <div className="flex-1">
            <h5 className="mb-2 fs-9">Description</h5>
            	<textarea placeholder="Enter notes" name="notes" value={saveOtherNotes.values.notes} onBlur={saveOtherNotes.handleBlur} onChange={saveOtherNotes.handleChange} className="form-control"></textarea>
								
				{
                saveOtherNotes.errors.notes && saveOtherNotes.touched.notes?<div className="alert alert-danger small alert-dismissible" role="alert">{saveOtherNotes.errors.notes}</div>:null
              }
		  </div>
		  <input type="hidden" name="hiddenid" id="hiddenid" value={rowdata.itemid?rowdata.itemid:null}/>
		  <input type="hidden" name="remarks" id="remarkshidden" onBlur={saveOtherNotes.handleBlur} onChange={saveOtherNotes.handleChange} value={rowdata.remark?rowdata.remark:null}/>

		 

        </div>
		<div className="d-block mt-1">
				<button className="btn btn-outline-info">Save</button>
          </div>
      </div>
     
    </div>
	</form>
  </div>

</div>

       
      </Modal>
    </>





	<>
    
	<Modal show={showModal} onHide={handleCloseModal}>
	  
	  <div className="modal-body p-0">
				<div className="rounded-top-3 bg-body-tertiary py-3 ps-4 pe-6">
					<div className="title flex_middle">
						<div className="fs-12" style={{color:'#4fd044',fontSize:'16px',textAlign:'center'}} >Send whatsapp Message</div>
					</div>

					<div className="position-absolute top-0 end-0 mt-3 me-3 z-1">
						<button onClick={handleCloseModal}  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close" />
					</div>
				</div>

					<div className="row justify-content-center m-5">
						<div className="col-lg-10">
						{whatsapp_err && (
								<div className='text-white bg-danger rounded p-1 mb-1'>{whatsapp_err}</div>
								)}
								
								
								<div className="form-grooup">
									<input type="text" pattern="/^[0-9]{10}$/" className="form-control form-control-sm" onChange={onChangeWAFields} name="mobileNumber" id="mobileNumber" defaultValue={formData.mobileNumber}  placeholder="Mobile No."/>
								</div>
								<div className="form-grooup">
									<textarea className="form-control mt-4" onChange={onChangeWAFields}  name="message"  placeholder="Enter message here">{formData.message}</textarea>
								</div>

								<div className="form-grooup text-center mt-4">
									<button className="btn btn-sm btn-outline-success mt-3" onClick={handleSendMessage}>Send</button>
								</div>
						</div>
					</div>
					
				</div>
	</Modal>
  </>


		</div>








	);
};

export default CallingList;
